import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import PostCard from "../components/PostCard"
import ListPagenation from "../components/ListPagenation"
import SearchEngineOptimization from "../components/SearchEngineOptimization";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  desc: {
    paddingLeft: "0.5rem"
  }
}))

const BlogList = ({ data, location, pageContext }) => {
  const classes = useStyles()
  const siteMetadata = pageContext.siteMetadata
  const categories = pageContext.categories

  return (
    <Layout
      siteMetadata={siteMetadata}
      categories={categories}
    >
      <SearchEngineOptimization
        pagetitle={`ブログ一覧ページ(${pageContext.pageNo}ページ中${pageContext.lastPageNo}ページ目)`}
        pagedesc={`ブログ一覧ページ(${pageContext.pageNo}ページ中${pageContext.lastPageNo}ページ目)`}
        pagepath={location.pathname}
        hero={pageContext.categoryHero}
      />

      <Container>
        <Typography variant="h6">{`ブログ`}</Typography>
        <Typography className={classes.desc} variant="body2">{`投稿順にお読みいただけます。`}</Typography>

        <hr />

        <Grid container spacing={4}>
          {data.allMicrocmsBlog.edges.map(edge => (
            <PostCard edge={edge} categorySlug={`blog`} key={edge.node.id} />
          ))}
          {data.allMicrocmsBlog.edges.length <= 0 &&
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1">まだなにも投稿されていません。</Typography>
            </Grid>
          }
        </Grid>
        <ListPagenation pageNo={pageContext.pageNo} lastPageNo={pageContext.lastPageNo} />

      </Container>
    </Layout>
  )
}

export const query = graphql`
query ($skip: Int!, $limit: Int!) {
  allMicrocmsBlog(
    sort: {fields: publishedAt, order: DESC}
    skip: $skip
    limit: $limit
  ) {
    edges {
      node {
        id
        slug
        title
        eyecatch {
          url
        }
        publishedAt
        fields {
          blogDescription
        }
      }
    }
  }
}
`

export default BlogList